import { defineStore } from "pinia";

import { getPlanID } from "@/router/services.js";
import {
  ActionItem,
  FindActionItem200Response,
  VerfahrensteilschrittDetailRest,
  VerfahrensteilschrittUebersichtRest,
} from "@/services/open-api";
import { openAPIFactory } from "@/services/open-api.js";
import { useAppStore } from "@/stores/app.ts";

export interface TasksStoreState {
  vtsContainer: {
    [planID: string]: {
      [vtsID: string]: VerfahrensteilschrittDetailRest[] | string;
    };
  };
  vtsOverview: VerfahrensteilschrittUebersichtRest[];
  reloadActionItems: string[];
  showLoadingIndicator: boolean;
}

export const useTasksStore = defineStore("tasks", {
  state: (): TasksStoreState => ({
    vtsContainer: {},
    vtsOverview: [],
    reloadActionItems: [],
    showLoadingIndicator: true,
  }),
  actions: {
    setShowLoadingIndicator(value: boolean) {
      this.showLoadingIndicator = value;
    },

    /**
     * Loads VTS data by a given ID.
     *   The store's dispatch function
     *   If a string is provided, it is used as the vtsID. If an object is provided,
     *   it is expected, that vtsID is a nested property and a reload of already
     *   loaded and cached data is forced. This is necessary, since a dispatch only
     *   takes a single argument.
     */
    loadVtsData(vtsID: string, forceReload: boolean) {
      const appStore = useAppStore();
      const planID = getPlanID();

      if (
        planID !== undefined &&
        (typeof this.vtsContainer[planID] === "undefined" ||
          typeof this.vtsContainer[planID][vtsID] === "undefined" ||
          (forceReload && this.vtsContainer[planID][vtsID] !== "loading"))
      ) {
        if (this.showLoadingIndicator) {
          appStore.showPageLoadingIndicator({
            id: "loadVTS",
            text: "Einen Moment bitte, der Verfahrensteilschritt wird geladen.",
          });
        }
        this.setShowLoadingIndicator(true);

        if (typeof this.vtsContainer[planID] === "undefined") {
          this.vtsContainer[planID] = {};
        }

        this.vtsContainer[planID][vtsID] = "loading";

        openAPIFactory
          .verfahrensteilschrittResourceApiFactory()
          .getVerfahrensteilschrittDetail(planID, vtsID)
          .then((response) => {
            const vtsData = response.data;

            this.setVtsData({ planID, vtsID, vtsData });

            appStore.hidePageLoadingIndicator("loadVTS");
          })
          .catch((error) => {
            appStore.hidePageLoadingIndicator("loadVTS");

            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Das Laden des Verfahrensteilschrittes ist fehlgeschlagen!",
            });
          });
      }
    },
    /**
     * Creates a new round to an existing verfahrensteilschritt
     * @param payload
     * @param payload.planID The proceeding ID of the proceeding the new round should get added to.
     * @param payload.vtsID The ID of the VTS where the new round should get added to
     * @param payload.callbackSuccess Potential callback function for successful requests
     * @param payload.callbackError Potential callback function for request errors
     */
    addNewRoundToVTS(payload: {
      planID: string;
      vtsID: string;
      callbackSuccess: () => void;
      callbackError: () => void;
    }) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "addRound",
        text: "Moment. Ein neuer Durchgang wird hinzugefügt.",
      });

      openAPIFactory
        .verfahrensteilschrittResourceApiFactory()
        .createDurchgangVts(payload.planID, payload.vtsID)
        .then(() => {
          appStore.hidePageLoadingIndicator("addRound");

          this.loadVtsData(payload.vtsID, true);

          if (payload.callbackSuccess) {
            payload.callbackSuccess();

            appStore.showIndicationModal({
              status: "success",
              info: `Der Durchgang wurde angelegt.`,
              buttontext: "Schließen",
              headline: "Durchgang erfolgreich hinzugefügt",
            });
          }
        })
        .catch((error) => {
          if (payload.callbackError) {
            payload.callbackError();
          }

          appStore.hidePageLoadingIndicator("addRound");

          appStore.showErrorModal({
            response: error,
            customErrorMessage: "Fehler beim Anlegen eines neuen Durchgangs aufgetreten!",
          });
        });
    },
    /**
     * Deletes a round in an existing verfahrensteilschritt
     * @param payload
     * @param payload.planID The proceeding ID of the proceeding the new round should get added to.
     * @param payload.vtsID The ID of the VTS where the new round should get added to
     * @param payload.vtsRoundID The ID of the Round to be deleted
     */
    deleteRoundInVTS(payload: { planID: string; vtsID: string; vtsRoundID: number }) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "deleteRound",
        text: "Moment. Der Durchgang wird gelöscht.",
      });

      openAPIFactory
        .verfahrensteilschrittResourceApiFactory()
        .deleteDurchgangVts(payload.planID, payload.vtsID, payload.vtsRoundID)

        .then(() => {
          appStore.hidePageLoadingIndicator("deleteRound");

          this.loadVtsData(payload.vtsID, true);
        })
        .catch((error) => {
          appStore.hidePageLoadingIndicator("deleteRound");
          appStore.showErrorModal({
            response: error,
            customErrorMessage: "Fehler beim Löschen des Durchgangs aufgetreten!",
          });
        });
    },
    /**
     * Sets VTS data and triggers reactivity.
     * @param payload
     * @param payload.planID The proceeding ID
     * @param payload.vtsID The VTS ID
     * @param payload.vtsData Complete data of a VTS including all rounds
     */
    setVtsData(payload: {
      planID: string;
      vtsID: string;
      vtsData: VerfahrensteilschrittDetailRest[];
    }) {
      const vtsContainer = JSON.parse(JSON.stringify(this.vtsContainer));

      vtsContainer[payload.planID][payload.vtsID] = payload.vtsData;

      this.vtsContainer = vtsContainer;
    },
    /**
     * Set the completion status of a task
     * @param payload
     * @param payload.planID The proceeding ID the task status should get set
     * @param payload.vtsID
     * @param payload.vtsRoundID
     * @param payload.aufgabenID The ID of the task the status should get set for
     * @param payload.finished True if finished
     */
    setTaskFinished(payload: {
      planID: string;
      vtsID: string;
      vtsRoundID: number;
      aufgabenID: string;
      finished: boolean;
    }) {
      const appStore = useAppStore();

      let apiMethod;

      if (payload.finished) {
        apiMethod = openAPIFactory.aufgabeResourceApiFactory().setAufgabeUnerledigt;
      } else {
        apiMethod = openAPIFactory.aufgabeResourceApiFactory().setAufgabeErledigt;
      }

      apiMethod(payload.planID, payload.vtsRoundID, payload.aufgabenID)
        .then(() => {
          this.loadVtsData(payload.vtsID, true);
          this.loadVtsOverview(payload.planID);
        })
        .catch((error) => {
          appStore.showErrorModal({
            response: error,
            customErrorMessage: "Das Setzen des Aufgabenstatus ist fehlgeschlagen!",
          });
        });
    },
    /**
     * Loads the VTS overview data for a given plan ID.
     * @param planID The ID of the plan for which to retrieve VTS overview data.
     */
    loadVtsOverview(planID: string) {
      const appStore = useAppStore();

      openAPIFactory
        .verfahrensteilschrittResourceApiFactory()
        .getVerfahrensteilschritteUebersicht(planID)

        .then((response) => {
          this.vtsOverview = response.data;
        })
        .catch((error) => {
          appStore.showErrorModal({
            response: error,
            customErrorMessage: "Laden der Verfahrensteilschritte ist fehlgeschlagen!",
          });
        });
    },
    /**
     * Updates a specific action item
     * @param payload
     * @param payload.actionItemID The ID of the action item
     * @param payload.actionItem The details to update
     * @param payload.files Am array of files
     * @param showErrorMessage Should error message be shown
     */
    updateActionItem(
      payload: {
        actionItemID: string;
        actionItem: FindActionItem200Response;
        files: File[];
      },
      showErrorMessage = true,
    ): Promise<ActionItem | false> {
      const appStore = useAppStore();
      const planID = getPlanID();

      if (planID) {
        return new Promise((resolve, reject) => {
          openAPIFactory
            .actionItemResourceApiFactory()
            .updateActionItem(payload.actionItem, planID, payload.actionItemID, payload.files)
            .then((response) => {
              if (
                response.data.reloadVts &&
                Array.isArray(response.data.reloadVts) &&
                response.data.reloadVts.length
              ) {
                for (const vts of response.data.reloadVts) {
                  if (vts.code) {
                    this.loadVtsData(vts.code, true);
                  }
                }
              }

              resolve(response.data);
            })
            .catch((error) => {
              if (showErrorMessage) {
                appStore.showErrorModal({
                  response: error,
                  customErrorMessage:
                    "Während des Speicherns der Änderung ist ein Fehler aufgetreten!",
                });
              }

              reject(error);
            });
        });
      }

      return new Promise((resolve) => {
        resolve(false);
      });
    },
    /**
     * Loads a single action item by its ID
     * @param actionItemID The action item ID that should get loaded
     */
    getActionItem(actionItemID: string): Promise<ActionItem | false> {
      const appStore = useAppStore();
      const planID = getPlanID();

      if (planID) {
        return new Promise((resolve, reject) => {
          openAPIFactory
            .actionItemResourceApiFactory()
            .findActionItem(planID, actionItemID)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Das Laden des ActionItems ist fehlgeschlagen!",
              });

              reject(error);
            });
        });
      }

      return new Promise((resolve) => {
        resolve(false);
      });
    },
    /**
     * Updates a single VTS round data object and triggers reactivity.
     * @param payload
     * @param payload.planID The proceeding ID
     * @param payload.vtsID The VTS ID
     * @param payload.roundID The round ID
     * @param payload.roundData The round data to update
     */
    setVtsRound(payload: {
      planID: string;
      vtsID: string;
      roundID: number;
      roundData: VerfahrensteilschrittDetailRest;
    }) {
      if (typeof this.vtsContainer[payload.planID][payload.vtsID] !== "string") {
        const vtsContainer = { ...this.vtsContainer };
        const vtsData = vtsContainer[payload.planID][
          payload.vtsID
        ] as VerfahrensteilschrittDetailRest[];

        vtsData.splice(
          vtsData.findIndex((round) => Number(round.durchgangszaehler) === Number(payload.roundID)),
          1,
          payload.roundData,
        );

        this.vtsContainer = vtsContainer;
      }
    },
    /**
     * Adds an action item type to the reload list
     * @param type The type identifier of the action item
     */
    addActionItemToReloadList(type: string) {
      this.reloadActionItems = [...this.reloadActionItems, type];
    },
    /**
     * Removes an action item type from the reload list
     * @param type The type identifier of the action item
     */
    removeActionItemFromReloadList(type: string) {
      this.reloadActionItems = this.reloadActionItems.filter((item) => item !== type);
    },
  },
  getters: {
    /**
     * Returns a function that retrieves the VTS data for a given VTS ID.
     */
    vtsData(): (planID: string, vtsID: string) => VerfahrensteilschrittDetailRest[] {
      return (planID, vtsID) => {
        if (
          typeof this.vtsContainer[planID] !== "undefined" &&
          typeof this.vtsContainer[planID][vtsID] !== "undefined" &&
          this.vtsContainer[planID][vtsID] !== "loading"
        ) {
          return this.vtsContainer[planID][vtsID] as VerfahrensteilschrittDetailRest[];
        }

        return [];
      };
    },
    /**
     * Returns a function that retrieves the highest round ID of a VTS step.
     */
    vtsDataLastRoundID(): (planID: string, vtsID: string) => number | undefined {
      return (planID, vtsID) => {
        const vtsData = this.vtsData(planID, vtsID);

        return vtsData.length
          ? vtsData
              .map((round) => Number(round.durchgangszaehler))
              .reduce((max, current) => (current > max ? current : max))
          : undefined;
      };
    },
    /**
     * Returns a function that retrieves the round data of a desired VTS round.
     */
    vtsRoundData(): (
      planID: string,
      vtsID: string,
      roundID?: number,
    ) => VerfahrensteilschrittDetailRest | undefined {
      return (planID, vtsID, roundID) => {
        const vtsData = this.vtsData(planID, vtsID);
        const vtsRoundID =
          roundID !== undefined ? Number(roundID) : this.vtsDataLastRoundID(planID, vtsID);

        return vtsData.length
          ? vtsData.find((roundData) => Number(roundData.durchgangszaehler) === vtsRoundID)
          : undefined;
      };
    },
  },
});
