<script>
  import { getEnvironmentLinks } from "@/services/location.ts";

  export default {
    name: "ThePageFooter",
    computed: {
      currentYear() {
        return new Date().getFullYear();
      },
      datenschutzUrl() {
        const portalLinkBasedOnEnvironment = getEnvironmentLinks().portal;

        return `//${portalLinkBasedOnEnvironment}/datenschutz`;
      },
    },
    methods: {
      handleRouteClick(routePath) {
        if (localStorage.isLogoutVisible) {
          localStorage.setItem("selectedRoute", routePath);
        }
      },
    },
  };
</script>

<template>
  <footer class="dpc-page-footer">
    <span>© {{ currentYear }} Behörde für Stadtentwicklung und Wohnen Hamburg</span>
    <div class="d-flex gap-4">
      <router-link to="/impressum" title="Impressum" @click="handleRouteClick('/impressum')"
        >Impressum</router-link
      >
      <a :href="datenschutzUrl" title="Datenschutz" target="_blank" rel="noopener noreferrer">
        Datenschutz
      </a>
      <router-link
        to="/barrierefreiheit"
        title="Barrierefreiheit"
        @click="handleRouteClick('/barrierefreiheit')"
        >Barrierefreiheit</router-link
      >
      <router-link
        to="/barrierefreiheit#barriere-melden"
        title="Barriere melden"
        @click="handleRouteClick('/barrierefreiheit#barriere-melden')"
        >Barriere melden</router-link
      >
    </div>
  </footer>
</template>

<style lang="scss">
  @import "@/styles/imports";

  .dpc-page-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 72px;
    background: var(--dps-color-background);
    padding: 24px 32px;
    gap: 8px;
    border-top: 1px solid #eaebed;
    margin-top: auto; // position footer at bottom for short content

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    span,
    a {
      color: var(--dps-color-black);
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
    }

    .has-fixed-footer & {
      margin-top: 0;
    }
  }
</style>
