/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.130.1
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { StammdatenRest } from '../models';
/**
 * PlannameValidatorkonfigurationResourceApi - axios parameter creator
 * @export
 */
export const PlannameValidatorkonfigurationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Liefert die Planname-Validatorkonfiguration aus</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert die Planname-Validatorkonfiguration aus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPlannameValidatorkonfiguration: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/planname-validatorkonfiguration/datei`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Upload der Planname-Validatorkonfiguration.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Upload der Planname-Validatorkonfiguration.
         * @param {File} plannameValidatorkonfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPlannameValidatoren: async (plannameValidatorkonfiguration: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plannameValidatorkonfiguration' is not null or undefined
            assertParamExists('uploadPlannameValidatoren', 'plannameValidatorkonfiguration', plannameValidatorkonfiguration)
            const localVarPath = `/konfigurationen/planname-validatorkonfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (plannameValidatorkonfiguration !== undefined) { 
                localVarFormParams.append('plannameValidatorkonfiguration', plannameValidatorkonfiguration as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlannameValidatorkonfigurationResourceApi - functional programming interface
 * @export
 */
export const PlannameValidatorkonfigurationResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlannameValidatorkonfigurationResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Liefert die Planname-Validatorkonfiguration aus</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert die Planname-Validatorkonfiguration aus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPlannameValidatorkonfiguration(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPlannameValidatorkonfiguration(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlannameValidatorkonfigurationResourceApi.downloadPlannameValidatorkonfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Upload der Planname-Validatorkonfiguration.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Upload der Planname-Validatorkonfiguration.
         * @param {File} plannameValidatorkonfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPlannameValidatoren(plannameValidatorkonfiguration: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StammdatenRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPlannameValidatoren(plannameValidatorkonfiguration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlannameValidatorkonfigurationResourceApi.uploadPlannameValidatoren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PlannameValidatorkonfigurationResourceApi - factory interface
 * @export
 */
export const PlannameValidatorkonfigurationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlannameValidatorkonfigurationResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Liefert die Planname-Validatorkonfiguration aus</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert die Planname-Validatorkonfiguration aus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPlannameValidatorkonfiguration(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.downloadPlannameValidatorkonfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Upload der Planname-Validatorkonfiguration.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Upload der Planname-Validatorkonfiguration.
         * @param {File} plannameValidatorkonfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPlannameValidatoren(plannameValidatorkonfiguration: File, options?: any): AxiosPromise<StammdatenRest> {
            return localVarFp.uploadPlannameValidatoren(plannameValidatorkonfiguration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlannameValidatorkonfigurationResourceApi - object-oriented interface
 * @export
 * @class PlannameValidatorkonfigurationResourceApi
 * @extends {BaseAPI}
 */
export class PlannameValidatorkonfigurationResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Liefert die Planname-Validatorkonfiguration aus</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Liefert die Planname-Validatorkonfiguration aus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlannameValidatorkonfigurationResourceApi
     */
    public downloadPlannameValidatorkonfiguration(options?: RawAxiosRequestConfig) {
        return PlannameValidatorkonfigurationResourceApiFp(this.configuration).downloadPlannameValidatorkonfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Upload der Planname-Validatorkonfiguration.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Upload der Planname-Validatorkonfiguration.
     * @param {File} plannameValidatorkonfiguration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlannameValidatorkonfigurationResourceApi
     */
    public uploadPlannameValidatoren(plannameValidatorkonfiguration: File, options?: RawAxiosRequestConfig) {
        return PlannameValidatorkonfigurationResourceApiFp(this.configuration).uploadPlannameValidatoren(plannameValidatorkonfiguration, options).then((request) => request(this.axios, this.basePath));
    }
}

