/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.130.1
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { UnterverfahrensteilschrittRest } from '../models';
/**
 * UnterverfahrensteilschrittResourceApi - axios parameter creator
 * @export
 */
export const UnterverfahrensteilschrittResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Legt einen neuen Durchgang eines Unterverfahrensteilschritts an.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Legt einen neuen Durchgang eines Unterverfahrensteilschritts an.
         * @param {string} planID planID des Verfahrens
         * @param {string} codeUvts CodeUnterverfahrensteilschritte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neuerDurchgangUvts: async (planID: string, codeUvts: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('neuerDurchgangUvts', 'planID', planID)
            // verify required parameter 'codeUvts' is not null or undefined
            assertParamExists('neuerDurchgangUvts', 'codeUvts', codeUvts)
            const localVarPath = `/verfahren/{planID}/unterverfahrensteilschritte/{codeUvts}/neuenDurchgangAnlegen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"codeUvts"}}`, encodeURIComponent(String(codeUvts)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeUvts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAktiv: async (planID: string, codeUvts: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('setAktiv', 'planID', planID)
            // verify required parameter 'codeUvts' is not null or undefined
            assertParamExists('setAktiv', 'codeUvts', codeUvts)
            const localVarPath = `/verfahren/{planID}/unterverfahrensteilschritte/{codeUvts}/aktiv`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"codeUvts"}}`, encodeURIComponent(String(codeUvts)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeUvts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInAktiv: async (planID: string, codeUvts: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('setInAktiv', 'planID', planID)
            // verify required parameter 'codeUvts' is not null or undefined
            assertParamExists('setInAktiv', 'codeUvts', codeUvts)
            const localVarPath = `/verfahren/{planID}/unterverfahrensteilschritte/{codeUvts}/inaktiv`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"codeUvts"}}`, encodeURIComponent(String(codeUvts)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnterverfahrensteilschrittResourceApi - functional programming interface
 * @export
 */
export const UnterverfahrensteilschrittResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnterverfahrensteilschrittResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Legt einen neuen Durchgang eines Unterverfahrensteilschritts an.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Legt einen neuen Durchgang eines Unterverfahrensteilschritts an.
         * @param {string} planID planID des Verfahrens
         * @param {string} codeUvts CodeUnterverfahrensteilschritte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neuerDurchgangUvts(planID: string, codeUvts: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnterverfahrensteilschrittRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neuerDurchgangUvts(planID, codeUvts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UnterverfahrensteilschrittResourceApi.neuerDurchgangUvts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeUvts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAktiv(planID: string, codeUvts: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnterverfahrensteilschrittRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAktiv(planID, codeUvts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UnterverfahrensteilschrittResourceApi.setAktiv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeUvts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInAktiv(planID: string, codeUvts: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnterverfahrensteilschrittRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInAktiv(planID, codeUvts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UnterverfahrensteilschrittResourceApi.setInAktiv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UnterverfahrensteilschrittResourceApi - factory interface
 * @export
 */
export const UnterverfahrensteilschrittResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnterverfahrensteilschrittResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Legt einen neuen Durchgang eines Unterverfahrensteilschritts an.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Legt einen neuen Durchgang eines Unterverfahrensteilschritts an.
         * @param {string} planID planID des Verfahrens
         * @param {string} codeUvts CodeUnterverfahrensteilschritte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neuerDurchgangUvts(planID: string, codeUvts: string, options?: any): AxiosPromise<UnterverfahrensteilschrittRest> {
            return localVarFp.neuerDurchgangUvts(planID, codeUvts, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeUvts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAktiv(planID: string, codeUvts: string, options?: any): AxiosPromise<UnterverfahrensteilschrittRest> {
            return localVarFp.setAktiv(planID, codeUvts, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeUvts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInAktiv(planID: string, codeUvts: string, options?: any): AxiosPromise<UnterverfahrensteilschrittRest> {
            return localVarFp.setInAktiv(planID, codeUvts, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnterverfahrensteilschrittResourceApi - object-oriented interface
 * @export
 * @class UnterverfahrensteilschrittResourceApi
 * @extends {BaseAPI}
 */
export class UnterverfahrensteilschrittResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Legt einen neuen Durchgang eines Unterverfahrensteilschritts an.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @summary Legt einen neuen Durchgang eines Unterverfahrensteilschritts an.
     * @param {string} planID planID des Verfahrens
     * @param {string} codeUvts CodeUnterverfahrensteilschritte
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnterverfahrensteilschrittResourceApi
     */
    public neuerDurchgangUvts(planID: string, codeUvts: string, options?: RawAxiosRequestConfig) {
        return UnterverfahrensteilschrittResourceApiFp(this.configuration).neuerDurchgangUvts(planID, codeUvts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @param {string} planID 
     * @param {string} codeUvts 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnterverfahrensteilschrittResourceApi
     */
    public setAktiv(planID: string, codeUvts: string, options?: RawAxiosRequestConfig) {
        return UnterverfahrensteilschrittResourceApiFp(this.configuration).setAktiv(planID, codeUvts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @param {string} planID 
     * @param {string} codeUvts 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnterverfahrensteilschrittResourceApi
     */
    public setInAktiv(planID: string, codeUvts: string, options?: RawAxiosRequestConfig) {
        return UnterverfahrensteilschrittResourceApiFp(this.configuration).setInAktiv(planID, codeUvts, options).then((request) => request(this.axios, this.basePath));
    }
}

