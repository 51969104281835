<script>
  import { mapStores } from "pinia";
  import { RouterView } from "vue-router";

  import AppModalConfirm from "@/components/AppModalConfirm.vue";
  import TheModalIndication from "@/components/TheModalIndication.vue";
  import ThePageFooter from "@/components/ThePageFooter.vue";
  import ThePageHeader from "@/components/ThePageHeader.vue";
  import ThePageLoadingIndicator from "@/components/ThePageLoadingIndicator.vue";
  import router from "@/router";
  import { useAppStore } from "@/stores/app.ts";
  import { useAuthStore } from "@/stores/auth.ts";

  export default {
    name: "App",
    components: {
      ThePageHeader,
      ThePageFooter,
      RouterView,
      AppModalConfirm,
      TheModalIndication,
      ThePageLoadingIndicator,
    },
    provide() {
      return {
        showConfirm: this.showConfirm,
      };
    },
    data() {
      return {
        confirm: {
          show: false,
          headline: "",
          infotext: "",
          callbackConfirmed: null,
          callbackConfirmedArguments: [],
          callbackConfirmedText: "OK",
          callbackDenied: null,
          callbackDeniedArguments: [],
          callbackDeniedText: "Abbrechen",
        },
      };
    },
    computed: {
      ...mapStores(useAuthStore, useAppStore),
      isPublicPage() {
        return router.currentRoute.value.meta.isPublicPage ?? false;
      },
    },
    watch: {
      $route(to, from) {
        const oldBodyClass = from.meta.bodyClass;
        const newBodyClass = to.meta.bodyClass;

        if (oldBodyClass) {
          document.body.classList.remove(oldBodyClass);
        }
        if (newBodyClass) {
          document.body.classList.add(newBodyClass);
        }
      },
    },
    async created() {
      await this.appStore.loadRuntimeEnvironment();
    },
    mounted() {
      const bodyClass = router.currentRoute.value.meta.bodyClass;

      if (bodyClass) {
        document.body.classList.add(bodyClass);
      }
    },
    beforeUnmount() {
      const bodyClass = router.currentRoute.value.meta.bodyClass;

      if (bodyClass) {
        document.body.classList.remove(bodyClass);
      }
    },
    methods: {
      showConfirm({
        headline,
        infotext,
        callbackConfirmed,
        callbackConfirmedArguments,
        callbackConfirmedText,
        callbackDenied,
        callbackDeniedArguments,
        callbackDeniedText,
        hasCancelButton,
        cancelButtonCallback,
        cancelButtonLabel,
        isDelete,
        className,
      }) {
        this.confirm = {
          show: true,
          className: className,
          headline: headline,
          infotext: infotext,
          callbackConfirmed: callbackConfirmed,
          callbackConfirmedArguments: Array.isArray(callbackConfirmedArguments)
            ? callbackConfirmedArguments
            : [],
          callbackConfirmedText: callbackConfirmedText !== undefined ? callbackConfirmedText : "OK",
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          callbackDenied: callbackDenied !== undefined ? callbackDenied : () => {},
          callbackDeniedArguments: Array.isArray(callbackDeniedArguments)
            ? callbackDeniedArguments
            : [],
          callbackDeniedText: callbackDeniedText !== undefined ? callbackDeniedText : "Abbrechen",
          hasCancelButton: hasCancelButton !== undefined ? hasCancelButton : false,
          cancelButtonCallback,
          cancelButtonLabel,
          isDelete: isDelete,
        };
      },
    },
  };
</script>

<template>
  <div v-if="authStore.authenticated || isPublicPage" class="app">
    <transition name="fade">
      <TheModalIndication />
    </transition>

    <transition name="fade">
      <AppModalConfirm
        v-if="confirm.show"
        :class="confirm.className"
        :headline="confirm.headline"
        :infotext="confirm.infotext"
        :callback-confirmed="confirm.callbackConfirmed"
        :callback-confirmed-arguments="confirm.callbackConfirmedArguments"
        :callback-confirmed-text="confirm.callbackConfirmedText"
        :callback-denied="confirm.callbackDenied"
        :callback-denied-arguments="confirm.callbackDeniedArguments"
        :callback-denied-text="confirm.callbackDeniedText"
        :has-cancel-button="confirm.hasCancelButton"
        :cancel-button-callback="confirm.cancelButtonCallback"
        :cancel-button-label="confirm.cancelButtonLabel"
        :is-delete="confirm.isDelete"
        @close-modal="confirm.show = false"
      />
    </transition>

    <ThePageLoadingIndicator />
    <ThePageHeader
      :user-name="authStore.idTokenParsed ? authStore.idTokenParsed.name : 'unbekannter Nutzer'"
    />
    <RouterView />
    <ThePageFooter />
  </div>
</template>

<style lang="scss">
  .app {
    background: var(--dps-color-white);
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
  }
</style>
